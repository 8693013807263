@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-200;
}

mark {
  @apply bg-primaryLight;
}

a,
button.link {
  @apply text-blue-500 hover:text-blue-600;
}

input,
select {

  &.branded {

    @apply px-2 pt-2 pb-1.5 bg-white rounded-sm shadow-inner focus:outline-none;

    &:not(disabled) {
      @apply focus:ring-2 focus:ring-black;
    }

  }

}

@mixin button-base {
  @apply px-4 md:px-8 py-1.5 font-medium shadow hover:shadow-lg rounded-full;
  @apply transition-shadow transition-colors;
}

button {

  &.branded {
    @include button-base;
    @apply bg-primary border-2 border-transparent hover:bg-primaryLight;
  }

  &.branded-alt {
    @include button-base;
    @apply text-blue-500 border-2 border-blue-500 hover:bg-blue-100;
  }

  &.button-sm {
    @apply px-4 py-0;
  }

  &[disabled] {
    @apply opacity-70;
  }

}

.bg-hover {
  @apply bg-gray-100;
}

.bg-hover:not([disabled]) {
  @apply hover:bg-blue-100;
}

.card {
  @apply p-1 rounded bg-white shadow;
}

.overlay .nsm-content {
  @apply rounded;
}

.modal-lg .nsm-dialog {
  max-width: 1040px;
}
